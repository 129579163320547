/* -------------------------------------------------------------------------- */
/* Copyright 2002-2024, OpenNebula Project, OpenNebula Systems                */
/*                                                                            */
/* Licensed under the Apache License, Version 2.0 (the "License"); you may    */
/* not use this file except in compliance with the License. You may obtain    */
/* a copy of the License at                                                   */
/*                                                                            */
/* http://www.apache.org/licenses/LICENSE-2.0                                 */
/*                                                                            */
/* Unless required by applicable law or agreed to in writing, software        */
/* distributed under the License is distributed on an "AS IS" BASIS,          */
/* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.   */
/* See the License for the specific language governing permissions and        */
/* limitations under the License.                                             */
/* -------------------------------------------------------------------------- */

define('opennebula/error',['require'],function(require) {
  var Error = function(resp) {
    var error = {};
    if (resp.responseText) {
      try {
        error = JSON.parse(resp.responseText);
      }
      catch (e) {
        error.error = {message: "It appears there was a server exception. Please check server's log."};
      };
    } else {
      error.error = {};
    }
    error.error.http_status = resp.status;
    return error;
  }

  return Error;
})
;
